.cookies-embeds-confirm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 0 1rem;

    &-wrapper {
        position: relative;
        height: 300px;
        width: 100%;
        max-width: 450px;
        background-color: lightgray;
    }
}