.c-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--border-color);
    padding: .6rem 0;
    @include font-style-small-caps;

    &__element {
        line-height: 1rem;
        padding-right: 10px;
        margin-left: var(--spacing-left);

        & a {
            padding-right: 10px;
        }
    }

    @media (max-width: 650px) {
        display: block;
    }
}