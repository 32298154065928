.p-shop {
    .heading {
        @include logo-font;
        font-size: 5rem;
        line-height: .9;
        margin-top: 5rem;

        @media screen and (max-width: $bp-sm) {
            font-size: 3rem;
        }
    }

    .shopify-buy-frame--product.shopify-buy__layout-horizontal {
        margin-left: 0;
        margin-right: 0;

        iframe {
            margin-left: 0;
            margin-right: 0;
        }
    }
    // .cards {
    //     display: flex;
    //     width: 100%;
    //     grid-gap: var(--gutter);
    //     max-width: 1100px;
    //     padding: 4rem 0;
    //     flex-wrap: wrap;
    // }

    // .card {
    //     border: 1px solid;
    //     text-align: left;
    //     max-width: 300px;
    //     width: 100%;
    //     padding: var(--gutter);
    //     display: flex;
    //     flex-direction: column;
    //     flex-shrink: 0;

    //     &__price {
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         font-family: var(--logo-font);
    //         color: var(--black);
    //         font-size: 5rem;
    //         line-height: 5rem;
    //         position: relative;
    //         margin-left: -1rem;
    //         margin-bottom: 1rem;

    //         span {
    //             font-size: 2rem;
    //             font-family: Arial, Helvetica, sans-serif;
    //             width: 1.7rem;

    //             i {
    //                 position: absolute;
    //             }
    //         }
    //     }

    //     &__title {
    //         text-transform: uppercase;
    //         text-align: center;
    //         border-bottom: 1px solid;
    //         margin-bottom: 1rem;
    //     }

    //     &__description {
    //         flex: 1 0 auto;
    //     }

    //     &__button {
    //         height: 70px;
    //         justify-self: flex-end;

    //         & .shopify-buy__btn {
    //             width: 100%;
    //         }
    //     }
    // }

    .verkaufsstellen {
        h2 {
            margin-top: 5rem;
            margin-bottom: 1.5rem;
        }
        ul {
            height: 320px;
            overflow: hidden;
            position: relative;

            &.is-open {
                height: auto;

                li:nth-child(n+6) {
                    visibility: visible;
                }
                
            }

            button {
                position: absolute;
                bottom: 0;
            }

            li {
                display: flex; 
                // align-items: center; 
                margin-bottom: .7rem;

                &:nth-child(n+6) {
                    visibility: hidden;
                }

                &:last-of-type {
                    padding-bottom: 2rem;
                }

                .icon {
                    font-size: 1.5em; margin-right: .5rem
                }
            }    
        }
    }

    .advantages {
        li {
            margin-bottom: 2rem;
        }
    }
}