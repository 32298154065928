:root {
    --tufte-width: 350px;
    --tufte-distance: 50px;
    --tufte-break: 800px;
    counter-reset: sidenote-counter;
}

.sidenote,
.marginnote {
    float: right;
    clear: right;
    width: var(--tufte-width);
    margin-right: calc(var(--tufte-width) * -1);
    margin-bottom: 1rem;
    padding-left: var(--tufte-distance);
    vertical-align: baseline;
    position: relative;

    /* custom styles */
    font-size: .7rem;
    user-select: none;
}

.sidenote-number {
    counter-increment: sidenote-counter;
}

.sidenote-number:after,
.sidenote:before {
    position: relative;
    vertical-align: baseline;
}

.sidenote-number:after {
    content: counter(sidenote-counter);
    font-size: .8rem;
    top: -0.5rem;
    left: 0.1rem;
    /* custom style */
    color: salmon;
}

.sidenote:before {
    content: counter(sidenote-counter) " ";
    font-size: .7rem;
    top: -0.5rem;

    /* custom style */
    top: 0;
    margin-right: 1rem;
    color: salmon;
}

input.margin-toggle {
    display: none;
}

label.sidenote-number {
    display: inline;
}

label.margin-toggle:not(.sidenote-number) {
    display: none;
}

@media (max-width: 800px) {
    label.sidenote-number {
        display: inline;
        cursor: pointer;
    }

    .sidenote,
    .marginnote {
        display: none;
    }

    label.margin-toggle:not(.sidenote-number) {
        display: inline;
        font-size: 1.3rem;
        line-height: 1rem;
        /* custom style */
        color: salmon;
        cursor: pointer;
    }

    .margin-toggle:checked + .sidenote,
    .margin-toggle:checked + .marginnote {
        display: block;
        float: left;
        left: 1rem;
        clear: both;
        width: 95%;
        margin: 1rem 2.5%;
        vertical-align: baseline;
        position: relative;
    }
}