/* Vars */
$main-left-padding: 200px;

$bp-lg: 900px;
$bp-md: 769px;
$bp-sm: 481px;

/* Mixins */
@mixin font-style-small-caps {
    text-transform: uppercase;
    font-family: var(--mono);
    font-size: .7rem;
}

@mixin logo-font {
    font-family: var(--logo-font);
    letter-spacing: -0.03em;
    // line-height: .8;
}

@mixin headline-sans {
    font-weight: 600;
    letter-spacing: -0.025em;
    line-height: 1;
}

@mixin main-headline {
    @include headline-sans;
    font-size: 5rem;
    width: 100%;

    @media (max-width: 900px) {
        font-size: 4rem;
    }

    @media (max-width: 769px) {
        font-size: 3rem;
    }

    @media (max-width: 481px) {
        font-size: 2rem;
    }
}

// @mixin mq-large {
//     @media(max-width: 900px) {
//         @content;
//     }
// }

// @mixin mq-med {
//     @media(max-width: 769px) {
//         @content;
//     }
// }

// @mixin mq-small {
//     @media(max-width: 481px) {
//         @content;
//     }
// }

// @import '../../../../node_modules/modern-normalize/modern-normalize.css';
@import './preflight.css';

@import './fonts.scss';

@import 'swiper/swiper-bundle.css';
@import 'plyr/dist/plyr.css';
@import 'line-awesome/dist/line-awesome/css/line-awesome.min.css';

@import './components/blocks.scss';
@import './components/main.scss';
@import './components/form.scss';
@import './components/main-menu.scss';
@import './components/article.scss';
@import './components/contact.scss';
@import './components/contributor.scss';
@import './components/event.scss';
@import './components/media.scss';
@import './components/footer.scss';
@import './components/cookie-modal.scss';
@import './components/tufte.css';
@import './components/event-card.scss';
@import './components/cookies-embeds-confirm.scss';
@import './components/banner.scss';
@import './components/period-plus-icon.scss';

@import './layouts/main.scss';

@import './pages/shop.scss';
@import './pages/media.scss';
@import './pages/articles.scss';

:root {

    /* ---- Color ------------- */
    --black: #252925;
    --border-color: #dddddd;
    --highlight-color: rgba(255, 140, 105, 0.2);

    /* ---- Layout ------------ */
    --gutter: 1rem;
    --spacing-left: 200px;
    --negative-spacing-left: -200px;
    --main-margin-top: 2rem;

    /* ---- Text -------------- */
    --mono: "IBM Plex Mono";
    --logo-font: "DM Serif Display", serif;
    --logo-font-size: 12rem;

    /* ---- Plyr -------------- */
    --plyr-color-main: salmon;
    --plyr-font-family: "Inter";
    --plyr-control-spacing: 1rem;
    --plyr-control-icon-size: 1.5rem;

    /* ---- Elements ---------- */
    --contributor-card-width: 250px;
    --playlist-width: 400px;
}

@media (max-width: 930px) {
    :root {
        --contributor-card-width: 180px;
    }
}

@media (max-width: 850px) {
    :root {
        --logo-font-size: 8rem;
    }
}

@media (max-width: 650px) {
    :root {
        --spacing-left: var(--gutter);
        --negative-spacing-left: calc(-1 * var(--gutter));
        --main-margin-top: 6rem;
    }
}

@media (max-width: 480px) {
    :root {
        --logo-font-size: 6rem;
        --contributor-card-width: 130px;
    }
}

html, body {
    height: 100%;
}

html {
    @media (max-width: 480px) {
        font-size: .95rem;
    }
}

body {
    font-family: "Inter", sans-serif;
    color: var(--black);
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

@supports (font-variation-settings: normal) {
    body { font-family: 'Inter var', sans-serif; }
}

h1 {
    font-size: 4rem;
}
h2 {
    font-size: 3rem;
    font-weight: 600;
}
h3 {
    font-size: 2rem;
    font-weight: 600;
}

h1, h2, h3, h4, h5, h6 {
    font-size: 2rem;
}

hr {
    width: calc(100% + var(--spacing-left) + var(--gutter));
    transform: translateX(var(--negative-spacing-left));
    margin: var(--gutter) 0;
}

p {
    max-width: 600px;
    
    & .highlight {
        background-color: var(--highlightColor);
    }

    & a {
        text-decoration: underline;
    }
}

b, strong {
    font-weight: 600;
}

figure {
    position: relative;
    overflow: hidden;

    & img + figcaption {
        text-align: right;
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 0 .3rem;
        @include font-style-small-caps;

        & p {
            display: inline;
        }
    }

    & img:hover + figcaption {
        background-color: white;
    }
}

iframe {
    max-width: 100%;
}

.small-text {
    @include font-style-small-caps;
}

.has-bottom-border {
    position: relative;
    
    &::after {
        content: "";
        width: calc(100% + var(--spacing-left));
        height: 1px;
        position: absolute;
        display: block;
        bottom: 0;
        left: var(--negative-spacing-left);
        background-color: var(--border-color);
    }
}

.has-top-border {
    position: relative;
    
    &::before {
        content: "";
        width: calc(100% + var(--spacing-left));
        height: 1px;
        position: absolute;
        display: block;
        top: 0;
        left: var(--negative-spacing-left);
        background-color: var(--border-color);
    }
}

.has-after-icon-on-hover {
    & i {
        opacity: 0;
        transition: opacity .1s linear;
    }
    &:hover i {
        opacity: 1;
    }
}


.gutter-spacer {
    display: block;
    position: relative;
    height: var(--gutter);
    width: var(--gutter);
}