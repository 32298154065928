@import url('https://rsms.me/inter/inter.css');

@font-face {
    font-family: 'DM Serif Display';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url('../fonts/dm-serif-display-v4-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/dm-serif-display-v4-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/dm-serif-display-v4-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/dm-serif-display-v4-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/dm-serif-display-v4-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/dm-serif-display-v4-latin-regular.svg#DMSerifDisplay') format('svg'); /* Legacy iOS */
}

// /* inter-regular - latin-ext_latin */
// @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 400;
//     src: url('../../fonts/inter-v2-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
//     src: local(''),
//          url('../../fonts/inter-v2-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//          url('../../fonts/inter-v2-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
//          url('../../fonts/inter-v2-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
//          url('../../fonts/inter-v2-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
//          url('../../fonts/inter-v2-latin-ext_latin-regular.svg#Inter') format('svg'); /* Legacy iOS */
// }

// /* inter-600 - latin-ext_latin */
// @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 600;
//     src: url('../../fonts/inter-v2-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
//     src: local(''),
//          url('../../fonts/inter-v2-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//          url('../../fonts/inter-v2-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
//          url('../../fonts/inter-v2-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
//          url('../../fonts/inter-v2-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
//          url('../../fonts/inter-v2-latin-ext_latin-600.svg#Inter') format('svg'); /* Legacy iOS */
// }

/* ibm-plex-mono-regular - latin-ext_latin */
@font-face {
    font-family: 'IBM Plex Mono';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url('../fonts/ibm-plex-mono-v6-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/ibm-plex-mono-v6-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/ibm-plex-mono-v6-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/ibm-plex-mono-v6-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/ibm-plex-mono-v6-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/ibm-plex-mono-v6-latin-ext_latin-regular.svg#IBMPlexMono') format('svg'); /* Legacy iOS */
}