body {
    &.is-overlayed {
        position: sticky;
        overflow: hidden;
        & main {
            pointer-events: none;
            transition: filter .1s linear;
            filter: blur(8px);
        }
        & .c-logo {
            opacity: 0;
        }
    }
    &::after {
        content: "";
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        border-left: 5px solid var(--editionColor);

        @media (max-width: 650px) {
            right: 0;
            height: 3px;
            width: 100%;
            border-left: 0;
            border-top: 5px solid var(--editionColor);
        }
    }
}

.c-logo {
    font-family: 'DM Serif Display', serif;
    font-size: 4rem;
    letter-spacing: -.12rem;
    font-weight: normal;
}

.c-site-header {
    position: fixed;
    display: flex;
    justify-content: space-between;
    top: 100%;
    width: 100vh;
    height: 100px;
    transform: rotate(-90deg);
    transform-origin: 0 0;
    padding: 1rem 1rem 0 3rem;
    z-index: 10000;

    @media (max-width: 650px) {
        transform: rotate(0);
        top: 0;
        width: 100%;
        height: 100px;
        padding: 1rem;
    }

    & .c-logo {
        z-index: 10000;

        @media (max-width: 650px) {
            display: none;
        }
    }

    &__nav-button {
        transform: rotate(90deg);
        height: 50px;
        margin-top: 20px;
        margin-right: 0;
        transition: all .1s linear;

        @media (max-width: 650px) {
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 0;
            transform: rotate(0)!important;
        }

        & path {
            fill: none;
            stroke: currentcolor;
            transition: .2s;
            transform-origin: center;
        }

        &::after {
            content: "Menu";
            @include font-style-small-caps;

            @media (max-width: 650px) {
                padding: .5rem;
            }
        }

        &:focus {
            outline: none;
            transform: rotate(90deg) scale(1.1);
        }

        &--close {
            // & .p1 { d: path("M20,80 L80,20"); }
            // & .p2 { d: path("M50,50 L50,50"); }
            // & .p3 { d: path("M20,20 L80,80"); }
            & path {
                stroke: white;
            }

            &::after {
                content: "Zurück";
                color: white;
            }

            background: transparent;
        }
    }
}

main {
    padding-left: var(--spacing-left);
    margin-right: var(--gutter);
    flex-grow: 1;

    margin-top: var(--main-margin-top);

    // &:not(.main-home):not(.main-article):not(.main-contributor) {
    //     margin-top: 2rem;
    // }
}