.cookie-modal {
    justify-content: flex-end;
    align-items: flex-end;
    background-color: transparent;
    z-index: 10000;

    &__content {
        margin: 10px;
        border-radius: 3px;
        border: 1px solid lightgray;
        backdrop-filter: blur(8px);
        -webkit-backdrop-filter: blur(8px);
        background-color: rgba(255,255,255,0.5);
        
        @supports not (backdrop-filter: blur(8px)) {
            background-color: rgba(255,255,255,1);
        }
    }

    &__label {
        white-space: nowrap;
    }


    &:not(.cookie-modal--hidden) {
        display: flex;
    }
}

.cookie-modal__button.primary:focus, .cookie-modal__button.primary:hover {
    border-color: salmon;
    background-color: salmon;
}

.cookie-modal__button:focus, .cookie-modal__button:hover {
    border-color: salmon;
}

.cookie-modal__checkbox:checked:focus {
    background-color: salmon;
}

.cookie-modal__checkbox:focus {
    border-color: salmon;
}

.cookie-modal__checkbox + .cookie-modal__check {
    opacity: 0;
}

.cookie-modal__checkbox:checked + .cookie-modal__check {
    opacity: 1;
}