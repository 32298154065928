.c-contributors {
    &--grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(calc(var(--contributor-card-width) + 20px), 1fr));
        margin: calc(2 * var(--gutter)) 0;
        margin-left: calc(var(--gutter) * -5);

        @media (max-width: 650px) {
            margin-left: 0;
        }

        @media (max-width: 480px) {
            display: block;
        }

        & .c-contributor__card {
            @media (max-width: 480px) {
                margin: var(--gutter) 0;
            }
        }

        & .c-contributor__header {
            display: flex;
            flex-direction: column;
            text-align: center;
            margin-right: 1rem;

            & h1 {
                font-size: 1.5rem;
            }

            @media (max-width: 480px) {
                flex-direction: row;
                text-align: left; 
            }
        }

        & .c-contributor__cover {
            margin-right: 0;
            margin-bottom: 1rem;

            @media (max-width: 480px) {
                margin-right: 20px;
            }
        }
    }
}

.c-contributor {
    &__card {

        margin: calc(2 * var(--gutter)) 0;

        & h1 {
            @include main-headline;
            margin-bottom: 0.5rem;
            margin-top: 0.1rem;
        }
    }
    &__header {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: center;
    }
    
    &__cover {
        border-radius: 50%;
        width: var(--contributor-card-width);
        height: var(--contributor-card-width);
        max-width: 100%;
        margin-right: var(--gutter);
        margin-bottom: var(--gutter);
        background-color: lightgray;
        border: 1px solid lightgray;
        grid-row: span 2;

        @media (max-width: 830px) {
            grid-row: span 1;
        }

        & img {
            object-fit: cover;
            object-position: center;
            height: 100%;
            width: 100%;
        }
    }

    &__about {
        grid-column: 2/-1;
        margin-bottom: 1rem;
        word-break: break-word;
    }
    &__intro {
        max-width: 600px;
        grid-column: 2/-1;
        align-self: start;

        @media (max-width: 830px) {
            grid-column: 1/-1;
        }
    }
}