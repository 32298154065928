.c-newsletter-banner {
    text-align: left!important;
    padding: calc(4* var(--gutter)) var(--gutter) calc(4* var(--gutter)) 0;
    // min-height: 350px;
    margin: calc(4 * var(--gutter)) 0;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--color);
    h1, h2 {
        font-family: "DM Serif Display";
        line-height: 1em;
        width: 100%!important;
        margin-bottom: 1rem;
    }

    h1 {
        font-size: 4rem;
        padding: 0;
        line-height: 1;

        @media (max-width: $bp-lg) {
            font-size: 3.5rem;
        }
        @media (max-width: $bp-lg) {
            font-size: 3rem;
        }
        @media (max-width: $bp-lg) {
            font-size: 2rem;
        }
    }
    &__text {
        font-size: 1.3rem;
        font-weight: 300;
        line-height: initial;
        padding-bottom: 1rem;
        max-width: 600px;

        & p {
            display: inline;
            background-color: rgba(255,255,255,0.7);
        }
    }
}

.c-banner {
    padding: calc(4* var(--gutter)) var(--gutter) calc(4* var(--gutter)) 0;
    // min-height: 350px;
    margin: calc(4 * var(--gutter)) 0;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--color);
    transform: rotate(-2deg);

    text-align: center;

    &__grid {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: 1rem;
        margin-bottom: .7rem;

        &__column {
            grid-column: span var(--span);
        }
    }

    h1 {
        font-size: 4rem;

        @media (max-width: $bp-lg) {
            font-size: 3.5rem;
        }
    }

    h2 {
        font-size: 4rem;

        @media (max-width: $bp-lg) {
            font-size: 3.5rem;
        }
        @media (max-width: $bp-lg) {
            font-size: 3rem;
        }
    }

    h3 {
        font-size: 1.5rem;
    }

    h1, h2, h3, h4, h5, h6 {
        width: 100%!important;
        line-height: 1.2;
        display: inline;
        background: white;
        padding: .07em .3em;
        box-decoration-break: clone;
    }

    h1, h2 {
        @include logo-font;
        font-weight: normal;
        letter-spacing: normal;
        // line-height: 1em;
        // width: 100%!important;
    }

    figure {
        max-width: 300px;
        margin: 0 auto;
    }

    p {
        margin: 0 auto;
        display: inline-block;
        background: white;
        padding: .1em .3em;
    }

    &__buttons {
        display: flex;
        flex-wrap: wrap;
        margin-top: 2rem;
    }

    .c-button {
        background: var(--color-highlight);
        box-shadow: none;
        margin-bottom: 0;
        margin-right: 0;
        font-weight: 600;
        padding: .3em .6em;
        &::after {
            display: none;
        }
        &:hover {
            transform: scale(1.1);
        }
    }
}