.c-media-slider {
    padding: calc(2*var(--gutter)) 0 0 0;
    height: auto;
    position: relative;
    background-color: #eee;

    &__heading {
        @include headline-sans;
        font-size: 2rem;
    }

    & .swiper-slide {
        width: 450px;
        max-width: 78vw;

        &:hover .c-card {
            transform: scale(.98);
        }
    }

    &::after {
        content: "";
        width: 60px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1));
        z-index: 1000;
    }
}

.c-card {
    // box-shadow: 0 0 10px rgba(0,0,0,0.2);
    margin: calc(1.5 * var(--gutter));
    &:first-child {
        margin-left: 0;
    }
    // max-width: 500px;
    position: relative;
    display: block;

    &__image {
        padding-bottom: 56.25%;
        height: 0;
        position: relative;
        margin-bottom: 1rem;
        & figure {
            position: absolute;
            height: 100%;
            width: 100%;
            border-radius: 3px;
            overflow: visible;
            & img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    &__text {
        opacity: 1;

        & h2 {
            @include headline-sans;
            font-size: 1.5rem;
            margin-bottom: .5rem;
        }
    }

    &__tags {
        position: absolute;
        color: white!important;
        top: 0;
        left: 0;
        padding: 0.5rem;
        background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0));
        border-radius: 3px;
        width: 100%;
        display: inline-flex;
        margin-bottom: .5rem;
        font-size: .7rem;
        margin-left: 1px;
    }

    transition: transform .1s linear;
}

.c-video {
    &__metatext {
        @include font-style-small-caps;
    }
}

.c-video-player {
    width: 800px;
    max-width: 100%;

    & #player {
        width: 100%;
        margin-bottom: var(--gutter);
        border-radius: 3px;
    }

    & .c-article__header {
        width: auto;
        margin-bottom: var(--gutter);
    }
}

.c-video-playlist {
    // border-left: 1px solid lightgray;
    width: var(--playlist-width);
    padding-left: 25px;
    height: 100%;
    background: rgba(255,255,255,0.7);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    position: fixed;
    top: 0;
    right: 0;
    // overflow-y: scroll;
    // -webkit-overflow-scrolling: touch;
    padding-top: var(--gutter);
    overflow: visible;
    overflow-y: hidden;

    & .swiper-wrapper {
        height: auto;
    }

    & .swiper-slide-active .c-card {
        transform: scale(1.05);
        transform-origin: center right;
    }

    & .swiper-button-prev {
        top: 10px;
        bottom: auto;
        width: 100%;
    }

    & .swiper-button-next {
        bottom: 10px;
        top: auto;
        width: 100%;
    }

    &__element {
        padding-left: var(--gutter);
        margin-bottom: 1rem;

        & img {
            border-radius: 3px;
        }

        & h2 {
            @include headline-sans;
            font-size: 1.5rem;
        }

        &.is-now-playing {
            & .c-card {
                border: 3px solid salmon;
                border-radius: 5px;
                box-sizing: border-box;
            }
        }
    }
}