.p-articles {
    .is-filtered-tag-container {
        margin-bottom: 3rem;
        .tag {
            border: 2px solid;
            display: inline-flex;
            text-transform: uppercase;
            font-size: 2em;
            @media screen and (max-width: 600px) {
                font-size: 1.2em;
            }
            .content {
                padding: .2em .7em;
            }
            .remove-tag {
                padding: .2em .7em;
                border-left: 2px solid;
            }
        }
    }

    .pagination {
        display: flex;
        flex-wrap: wrap;
        font-size: 2rem;
        width: 100%;
        max-width: 900px;
        justify-content: space-between;

        &__button {
            .c-button {
                margin-right: 0;
            }
        }
    }
}