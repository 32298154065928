.c-event {
    &-preview {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: calc(2*var(--gutter)) 0;

        & time {
            font-size: 2rem;
            // width: 400px;
            margin-right: 2rem;
        }

        & h1 {
            width: 500px;
            line-height: 1em;
        }
    }

    &__time {
        font-size: 3rem;
    }

    h1 {
        font-weight: 600;
    }
    time {

    }
}