.block {

}
.block-type-text p {
    padding-bottom: 1rem;
    margin-right: 10px;
}

.block-type-blockquote {
    padding: 2rem 0;
    font-family: "DM Serif Display";
    transform: translateX(2rem);
    
    @media screen and (max-width: $bp-sm) {
        transform: translateX(0);
    }
}

.block-type-heading {
    word-break: break-word;
    hyphens: auto;
    @include headline-sans;
}

.block-type-article {
    .c-article {
        margin-bottom: 0;
    }
}

.c-blockquote {
    position: relative;
    font-size: 3rem;
    line-height: 1;
    text-indent: 2rem;

    @media screen and (max-width: $bp-md) {
        font-size: 2rem;
    }

    
    & + .c-cite p {
        padding-top: 1rem;
    }

    &::before {
        content: "\201C";
        font-family: "DM Serif Display";
        position: absolute;
        top: -40px;
        left: -40px;
        font-size: 10rem;
        color: var(--border-color);
        z-index: -10;
    }
}

.c-cite {
    font-style: inherit;
    @include font-style-small-caps;
}

.c-button {
    position: relative;
    letter-spacing: -0.025em;
    // border: 1px solid;
    font-size: 2rem;
    // padding: 0.3rem 1rem;
    margin-bottom: 1rem;
    margin-right: 2rem;
    box-sizing: content-box;
    /* font-weight: 500; */
    /* background: lightsalmon; */
    // display: inline-block;
    z-index: 300;
    box-shadow: inset 0 -0.3em var(--highlightColor);
    -webkit-box-decoration-break: clone;
    -ms-box-decoration-break: clone;
    -o-box-decoration-break: clone;
    box-decoration-break: clone;
    transition: all .05s linear;
    &::after {
        content: " ";
        display: block;
        height: 1rem;
        pointer-events: none;
        // position: absolute;
        // z-index: 290;
        // bottom: 0;
        // left: -5px;
        // height: 50%;
        // width: calc(100% + 10px);
        // background: lightsalmon;
        // mix-blend-mode: multiply;
        // opacity: .6;
        // transition: all .05s linear;
    }
    &:hover {
        box-shadow: inset 0 -0.7em var(--highlightColor);
    }
    &--disabled {
        opacity: .5;
        box-shadow: none;
        &:hover {
            box-shadow: none;
        }
    }
}

.c-timetable {
    padding-left: 3rem;
    margin: 2rem 0;
    position: relative;
    &__row {
        // border-bottom: 1px solid var(--border-color);
        padding-bottom: 1rem;
        margin-bottom: 1rem;
    }

    &__header {
        position: relative;
        max-width: 100%;
        &::before {
            content: "";
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            // border: 2px solid;
            background: var(--black);
            position: absolute;
            top: 50%;
            left: -3rem;
        }
    }

    &__more {
        max-width: 100%;
    }

    &::before {
        content: "";
        position: absolute;
        width: 2px;
        height: calc(100% - 35px);
        background: var(--black);
        left: 6.5px;
        top: 35px;
    }
    // &__cell {
    //     display: block;
    //     border: 1px solid;

    //     @media screen and (min-width: 560px) {
    //         display: table-cell;
    //     }
    // }
}

.c-gallery {
    margin: 2rem 0;

    & .swiper-container {
        width: 100%;
        height: 60vh;
        margin-bottom: var(--gutter);
    
        & .swiper-slide {
            display: flex;
            align-items: center;
            width: auto;
            max-width: calc(100% - var(--spacing-left));

            & img {
                max-width: 100%;
                max-height: 100%;
            }
    
            &:first-child {
                margin-left: 0;
            }
    
            &::after {
                content: "";
                width: 100%;
                height: 100%;
                background-color: white;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
    
                transition: opacity .3s linear;
            }
    
            &:not(.swiper-slide-active)::after {
                opacity: .5;
            }
        }
    }
}


.swiper-captions-container {
    height: 3rem;
    position: relative;
    @include font-style-small-caps;

    & .swiper-pagination {
        display: inline-block;
        position: relative;
        text-align: left;
        width: 100px;
        top: 0;
    }

    & .swiper-caption {
        position: absolute;
        opacity: 0;
        pointer-events: none;
        width: calc(100% - 100px);
        display: inline-grid;
        grid-template-columns: repeat(2, 1fr);

        transition: opacity .1s linear;
    }
    & .is-visible {
        opacity: 1;
        pointer-events: all;
    }
}

.c-logos {
    display: flex;
    flex-wrap: wrap;
    padding: var(--gutter) 0;

    &__logo {
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: var(--gutter);
    }

    &.overlay .c-logos__logo {
        filter: contrast(0) sepia(100%) saturate(0) brightness(0);
    }
}

.block-type-metatext {
    @include font-style-small-caps;
}

.block-type-intro {
    font-size: 1.3rem;
    font-weight: 300;
    line-height: initial;
    padding-bottom: 1rem;
}

.block-type-factbox {
    max-width: 600px;
    border: 1px solid;
    padding: 1rem;

    p:not(:last-child) {
        margin-bottom: 1rem;
    }
}