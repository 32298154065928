.period-plus-icon {
    @include logo-font;
    color: white;
    font-size: 1.4rem;
    line-height: 1;
    opacity: .7;
    border: 1px solid white;
    background: black;
    border-radius: 3px;
    padding: .05em .2em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    
    display: none;
}