.c-article {
    position: relative;
    margin-bottom: 3rem;

    &--card {
        display: flex;
        flex-direction: column;
        gap: var(--gutter);
        align-items: flex-start;
        padding-top: var(--gutter);

        @media screen and (min-width: 900px) {
            flex-direction: row;
        }

        & a:not(.c-article__header__tag-container a) {
            @media screen and (max-width: 650px) {
                width: 100%;
            }
        }

        & figure {
            position: relative;
            margin-right: 1.5rem;
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 8px;
                height: 100%;
                background: 
                    var(--color);
                    // linear-gradient(
                    // to left, 
                    // var(--color), 
                    // var(--color) 20%, 
                    // transparent 20%,
                    // transparent 40%,
                    // var(--color) 40%,
                    // var(--color) 60%,
                    // transparent 60%,
                    // transparent 80%,
                    // var(--color) 80%,
                    // var(--color) 100%,
                    // );
            }
        }

        & figcaption {
            display: none;
        }

        & .c-article__coverwrapper {
            flex-grow: 1;
            width: 100%;
            max-width: 450px;
        }

        & .c-article__cover {
            width: 100%;
            height: 0;
            padding-top: 66%;
            overflow: hidden;

            & img {
                position: absolute;
                top: 0;
            }
        }

        & .c-article__byline {
            display: block;
            // grid-template-columns: repeat(4, 1fr);
        }

        & .c-article__author {
            text-indent: 0;
            padding-right: none;
        }

        & .c-article__contributors {
            display: none;
        }
    }

    &--lead {

        &:first-of-type {
            & .c-article__cover {
                margin-top: -2rem;
                @media (max-width: 650px) {
                    margin-top: calc(-1 * var(--main-margin-top))!important;
                }
            }
        }
        & .c-article__cover {

            height: calc(60vh + 2rem);
            width: calc(100% + var(--gutter));

            @media (max-width: 650px) {
                margin-left: calc(-1 * var(--gutter));
                width: calc(100% + (2 * var(--gutter)));
            }

            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        & .c-article__header {
            width: 100%;
            max-width: unset;
            margin-bottom: calc(3 * var(--gutter));
        }

        & .c-article__header h1 {
            font-size: 5rem;
            font-weight: 600;
            letter-spacing: -.025em;
            line-height: 1;
            width: 100%;

            @media (max-width: 900px) {
                font-size: 4rem;
            }

            @media (max-width: 769px) {
                font-size: 3rem;
            }

            @media (max-width: 481px) {
                font-size: 2rem;
            }
        }
    }

    & > * + * {
        margin-bottom: 1rem;
    }

    & h1, h2, h3, h4, h5, h6 {
        line-height: 1.3em;
        // width: 600px;
        max-width: 100%;
    }

    & h2, h3, h4, h5, h6 {
        width: 600px;
    }

    & figure:not(.c-article--card .c-article__cover) {
        margin-bottom: 1rem;
    }

    &__cover {
        width: 100%;

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__header {
        width: 100%;
        max-width: 450px;

        & h1 {
            font-weight: 600;
            letter-spacing: -.025em;
            line-height: 1;
            margin-bottom: .5rem;
            margin-top: .1rem;
        }

        &__tag-container {
            display: flex;
            align-items: center;
            margin-bottom: .5rem;
            gap: 1rem;
        }
    }

    &__intro {
        max-width: 450px;
    }

    &__byline {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        margin-top: 1rem;
        @include font-style-small-caps;

        & a {
            text-decoration: none;
        }
    }

    &__author {
        font-style: normal;
        text-transform: uppercase;
    }

    &__tags {
        display: flex;
        flex-wrap: wrap;
        font-size: .7rem;
        margin-left: 1px;
        color: grey;
    }

    &__tag {
        color: black;
        opacity: .6;
        padding: .2rem .7rem;
        text-transform: uppercase;
        border: 1px solid;
        transition: opacity .1s linear;
        margin-left: -1px;
        margin-top: -1px;
        width: auto!important;

        &--white {
            color: white;
        }

        &:hover {
            opacity: 1;
            z-index: 1000;
        }

        // &:not(:last-child) {
        //     border-right: none;
        // }

        // &::before {
        //     content: "#";
        // }
    }

}

.main-article {
    figure {
        margin-bottom: 2rem;
        &[data-size='inline'] {
            max-width: 600px;
        }
    
        &[data-size="large"] {
            width: calc(100% + var(--gutter));
    
            & img {
                width: 100%;
            }
        }
    
        &[data-size="full"] {
            width: calc(100% + var(--spacing-left) + var(--gutter));
            transform: translateX(calc(-1 * var(--spacing-left)));
    
            & img {
                width: 100%;
            }
        }
    }
}