.media-player {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem 2rem;

    @media screen and (max-width: 950px) {
        grid-template-columns: 1fr;
    }

    &__container {
        background: lightgray;
        position: relative;
        height: 0;
        padding-bottom: 56.25%;
        margin-bottom: 1rem;

        &.poster-permanent {
            .plyr__poster {
                opacity: 1;
            }
        }

        #player, .plyr--video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .plyr__poster {
                background-size: cover;
            }
        }
    }

    &__playlist {
        // grid-row: 1/ span 2;
        // grid-column: 2;
        
        &__item {
            display: grid;
            grid-template-columns: 180px 1fr;
            grid-gap: .5rem;
            margin-bottom: 1rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid lightgray;

            &.is-active {
                opacity: .5;
            }

            .image {
                height: 0;
                padding-bottom: 56.25%;
                position: relative;

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .title {
                font-weight: 600;
                letter-spacing: -0.025em;
                line-height: 1;
                font-size: 1.2rem;
                max-width: 20ch;
            }
        }
    }

    // @media screen and (max-width: 1000px) {
    //     &__playlist {
    //         grid-row: 2;
    //         grid-column: 1;
    //     }
    // }
}

.p-medien .c-article__header {
    margin-bottom: 2rem;
}