.l {
    &-grid {
        display: grid;
        &-4 {
            grid-template-columns: repeat(4, 1fr);
        }
        &-3 {
            grid-template-columns: repeat(3, 1fr);
        }
        &-12 {
            grid-template-columns: repeat(12, 1fr);
        }

        &__column {
            grid-column: span var(--span);

            & > * {
                margin-bottom: var(--gutter);
            }
        }
    }

    &-full-page-width {
        position: relative;
        width: calc(100% + #{$main-left-padding} + var(--gutter));
        transform: translate(-$main-left-padding);

        &__content {
            position: relative;
            padding-left: $main-left-padding;
        }
    }

    &-full-absolute {
        position: absolute;
        top:0;left:0;right:0;bottom:0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &-section {
        margin-top: calc(2 * var(--gutter));
    }

    &-section__heading {
        @include main-headline;
        margin-bottom: var(--gutter);
    }
}