.c-form {
    &__row {
        display: flex;
        justify-content: stretch;
        margin-bottom: 1rem;
    }
    &__input {
        font-size: 1.2rem;
        padding: .5rem 1rem;
        border: 1px solid;
        width: auto;
        margin-left: -1px;

        &--grow {
            flex-grow: 1;
        }
    }
}