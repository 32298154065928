.c-navigation {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9000;
    // background: var(--black);
    background: rgba(0,0,0,0.9);
    color: white;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;

    pointer-events: none;
    opacity: 0;
    transition: all .1s linear;

    &.is-open {
        opacity: 1;
        pointer-events: all;
        backdrop-filter: saturate(180%) blur(12px);
        -webkit-backdrop-filter: saturate(180px) blur(12px);
    }

    &__list {
        margin-top: 5rem;
    }

    &__logo-container {
        position: relative;
        margin-left: var(--spacing-left);
        display: flex;
        align-items: flex-start;
    }

    &__icons {
        font-size: 2rem;
        line-height: 2em;
        padding-left: var(--spacing-left);
    }

    &__icon {
        display: inline-flex;
        align-items: center;

        transition: all .1s linear;

        & i {
            margin-right: .3rem;

            @media screen and (max-width: 480px) {
                font-size: .7em;
            }
        }

        & span {
            margin-right: .5rem;
        }

        &:hover {
            transform: scale(.95);
        }
    }

    &__heading {
        font-size: var(--logo-font-size);
        @include logo-font;
    }

    &__element {
        padding-left: var(--spacing-left);
        padding-right: var(--gutter);
        text-align: left;
        font-weight: 600;
        font-size: 2rem;
        line-height: 2em;
        border-bottom: 1px solid var(--border-color);

        & a {
            transition: all .1s linear;
            &:hover, &:focus {
                outline: none;
                padding-left: 10px;
            }
            // transform: translateX(10px);
            // margin-left: 10px;
        }
    }
}